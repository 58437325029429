const handleHero = () => {
  const hero = document.querySelector('.hero');
  const capsule = document.querySelector('.hero__capsule');

  window.addEventListener('scroll', () => {
    if (hero.clientHeight > window.pageYOffset) {
      const topOffset = window.pageYOffset;
      const handledValue = `${topOffset}px`;

      capsule.style.transform = `translateX(-${handledValue})`;
    }
  });
};

const handleArrow = () => {
  const shelf = document.querySelector('.costumers__shelf');
  const arrow = document.querySelector('.costumers__arrow-wrapper');

  shelf.addEventListener('scroll', () => {
    if (shelf.scrollLeft > 5) {
      arrow.classList.add('--hide');
      return;
    }

    arrow.classList.remove('--hide');
  });
};

const setLinks = () => {
  const buttonsDesktop = document.querySelectorAll('.hero__menu-item');
  const buttonsMobile = document.querySelectorAll('.menu__item');
  const buttons = [...buttonsDesktop, ...buttonsMobile];
  const menu = document.querySelector('.menu');

  buttons.forEach((button) => {
    button.addEventListener('click', (event) => {
      const element = event.target;
      const linkDestiny = element.getAttribute('data-link');
      menu.classList.remove('--actived');
      document.querySelector(`.${linkDestiny}`).scrollIntoView({
        behavior: 'smooth',
      });
    });
  });

  document.querySelector('.footer__back-link').addEventListener('click', () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });

  document
    .querySelector('.hero__button-wrapper')
    ?.addEventListener('click', () => {
      document.querySelector('.experiences').scrollIntoView({
        behavior: 'smooth',
      });
    });
};

const handleMenu = () => {
  const closeButton = document.querySelector('.menu__close-button');
  const openButton = document.querySelector('.menu__open-button');
  const menu = document.querySelector('.menu');

  closeButton.addEventListener('click', () =>
    menu.classList.remove('--actived')
  );
  openButton.addEventListener('click', () => menu.classList.add('--actived'));
};

const createBlog = async () => {
  const blogSection = document.querySelector('.blog__posts');
  fetch(
    'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@caetanotona'
  )
    .then((res) => res.json())
    .then(({ items }) => {
      items.forEach((post) => {
        const template = `<div><a href="${post.link}" target="__blank" class="blog__link"><div class="blog__post">
        <div class="blog__post-image"><img class="blog__image" src='${post.thumbnail}'></div>

        <div class="blog__post-info">
          <p class="blog__post-title">${post.title}</p>
        </div>
      </div></a></div>`;

        blogSection.insertAdjacentHTML('beforeend', template);
      });

      createSlider();
    });
};

const createSlider = () => {
  tns({
    container: '.blog__posts',
    items: 1,
    slideBy: 'page',
    controls: false,
    center: true,
    slideBy: 1,
    nav: false,
    controlsText: ['↓', '↓'],
    responsive: {
      1250: {
        items: 3,
        center: false,
      },
      768: {
        items: 2,
        controls: true,
        center: false,
      },
    },
  });
};

const handleExperiences = () => {
  const cards = document.querySelectorAll('.experiences__card');

  if (window.innerWidth < 580) {
    cards.forEach((card) => {
      card.addEventListener('click', (event) => {
        const element = event.target;

        element.classList.toggle('--active');
      });
    });
  }
};

const handleToTop = () => {
  const button = document.querySelector('.to-top');
  const hero = document.querySelector('.hero');

  window.addEventListener('scroll', () => {
    if (window.scrollY > hero.scrollHeight) {
      button.classList.add('--active');
      return;
    }

    button.classList.remove('--active');
  });

  button.addEventListener('click', () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  });
};

window.addEventListener('DOMContentLoaded', () => {
  setLinks();
  handleArrow();
  handleMenu();
  createBlog();
  handleHero();
  handleExperiences();
  handleToTop();
});
